import React from 'react'
import { useQuery, gql } from '@apollo/client'
import { Button, Card, CardGroup } from 'react-bootstrap';
import  { Link } from 'react-router-dom'
import { EnvelopeAt } from "react-bootstrap-icons";

import { strapi_config } from 'Constants';

const USERLIST = gql `
query GetUsers {
	usersPermissionsUsers {
	  data {
		id,
		attributes {
		    username,
		    email,
		    headshot {
                data {
                    attributes {
                        url    
                    }
                }
            }
		}
	  }
	}
  }
`

export default function User() {

    const { loading, error, data } = useQuery(USERLIST, {
		onError: (error) => {
			console.log("Call back error: " + error.networkError.statusCode)
      		if (error.networkError.statusCode == 401) {
				console.log("Not authenticated.....")
				sessionStorage.removeItem('token')
			}
		}
	})

	if (loading) return <p>Please wait</p>
	
    if (error || data.error) return <p>Error 2 :( ... {error}</p>;

    return (

		<CardGroup>
			{ data.usersPermissionsUsers.data.map((user) => {return (
                <Card key={user.id}>
                    { user.attributes.headshot.data ? (
                        <Card.Img variant="left" 
                            src={strapi_config.url.BASE_STRAPI_URL + user.attributes.headshot.data.attributes.url} width="100" height="100"/>
                    ) : (
                        <Card.Img variant="left" 
                            src={strapi_config.url.BASE_STRAPI_URL + "/uploads/lady1_cd99fa5778.jpg"} width="100" height="100"/>
                    )
                    }
                <Card.Body>
                    <Card.Title>{user.attributes.username}</Card.Title>
                    <Card.Text><EnvelopeAt /> {user.attributes.email}</Card.Text>
                    <Link key={user.id} to={`/User/${user.id}`}>
                    <Button variant="primary">Open</Button>
                    </Link>
                </Card.Body>
                </Card>
			)}) }
		</CardGroup>)

//<p><Link to={`/SessionNotes/${user.id}`}>Details.</Link></p>
}

import { CheckBox } from '@mui/icons-material';
import React, { useState } from 'react';
import { Container, ListGroup, Row, ToggleButton} from 'react-bootstrap';

const ActionList = (actions) => {

    const {data} = actions;

    return (
        <Container>
            <ListGroup className="mt-4">
                {data.map((item, index) => (
                    <Row>
                        <ToggleButton id="toggle-check"
                            type="checkbox"
                            variant="secondary"
                            checked={item.complete}
                            value="1"></ToggleButton>
                        <ListGroup.Item key={index}>
                            {item.text} by the {item.date}: {item.description}
                        </ListGroup.Item>
                    </Row>
                ))}
            </ListGroup>
        </Container>
  );
};

export default ActionList;

const strapi_prod = {
	url: {
		//BASE_URL: 'http://192.168.178.98:1337',
        BASE_STRAPI_URL: 'https://martync123.co.uk/strapi',
        ADD_USER_URI: '/api/auth/local/register'
	},
};


export const strapi_config = strapi_prod;

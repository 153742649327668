import React from 'react'
import { useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import { useQuery, gql } from '@apollo/client'

//import useFetch from '../Hooks/useFetch'
import useToken from 'Hooks/useToken'

const NOTES = gql `
	query GetSessionNotes($id: ID!)  {
		sessionNotes(id: $id) {
			data {
				id
				attributes {
					SessionTitle
					SessionDate
					Notes
				}
			}
		}
	}
`

export default function SessionNotes() {

	const { id } = useParams()
	
	const { loading, error, data } = useQuery(NOTES, {
		variables: { id: id }
	})
	
	if (loading) return <p>Please wait</p>
	
	if (error) return <p>Error 2 :(</p>;
	
	console.log("SessionNotes: " + data)
	console.log(typeof(data))
	console.log(typeof(error))
	console.log(typeof(loading))
	console.log(data.sessionNotes.data.id)
	
	
	return (
		<div className="review-card">
			<div className="rating">{data.id}</div> 
			<h2>{data.sessionNotes.data.attributes.SessionTitle}</h2>			
			<p><small>When: {data.sessionNotes.data.attributes.SessionDate}</small></p>
			<p></p>
            <small>Note: <ReactMarkdown>{data.sessionNotes.data.attributes.Notes}</ReactMarkdown></small>
		</div>
	)

}
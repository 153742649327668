import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import Container from 'react-bootstrap/Container';
import Image from 'react-bootstrap/Image'
import { strapi_config } from 'Constants';


async function loginUser(credentials) {

  return fetch(strapi_config.url.BASE_STRAPI_URL + '/api/auth/local', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
}

export default function Login({ setToken }) {

  const [identifier, setUserName] = useState();
  const [password, setPassword] = useState();

  const handleSubmit = async e => {
    e.preventDefault();

    console.log("User is: " + identifier)
    const respData = await loginUser({
      identifier,
      password
    });

    console.log(respData)
    const token = respData.jwt
    console.log("Reply from host. JWT = " + token)
    setToken(token);
    window.location.reload();
  }

    return (
        <Container fluid="sm" className='main_wrap'>
            <Form onSubmit={handleSubmit}>
                <div>
                    <Image src={strapi_config.url.BASE_STRAPI_URL + "/uploads/Mindset_Revolution_Email_Logo_and_words_00e6e39f49.png"} width="500"/>
                    
                    <h1 className="login-wrapper">Please Log In</h1>
                    <Form.Group controlId='identifer' className="mb-3">
                        <Form.Control type="email" placeholder='name@example.com' onChange={e => setUserName(e.target.value)} />
                    </Form.Group>
                    <Form.Group controlId='passsword' className="mb-3">
                        <Form.Control type='password' placeholder='password' onChange={e => setPassword(e.target.value)} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Check type='checkbox' label='Remember me' size="sm" className="mb-3"/>
                    </Form.Group>
                    <div>
                        <Button type="submit">Submit</Button>
                    </div>
                </div>
            </Form>
        </Container>
    )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
}
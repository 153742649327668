import { useState } from "react"

export default function useToken() {
    
    const getToken = () => {
        const tokenString = sessionStorage.getItem('token');
        console.log("getToken 1: " + tokenString)
        const token = JSON.parse(tokenString);
        console.log("getToken 2: " + token)
        return token
    }

    const [token, setToken] = useState(getToken());
  
    const saveToken = userToken => {
      console.log("Save Token" + token)
      sessionStorage.setItem('token', JSON.stringify(userToken));
      setToken(userToken.token);
    };
  
    return {
      setToken: saveToken,
      token
    }
  }
import React from 'react'
import { Link } from 'react-router-dom'
import { Container, Row, Tab, Tabs, Table, Button } from 'react-bootstrap';

const SessionList = (sessionList) => {

    console.log("Session List Data:")
    console.log(sessionList)

    const userId = sessionList.sessionList.usersPermissionsUser.data.id
    return (
        <Container>
        <Row><Link to={`/NewSessionNotes/${userId}`}><Button>New Session</Button></Link></Row>
        <Row>
            <Tabs>
                <Tab eventKey="#sessions" title="Sessions">
                    <h1>Sessions</h1>
                    <Table>
                    <thead>
                        <tr>
                        <th>#</th>
                        <th>Title</th>
                        <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sessionList.sessionList.usersPermissionsUser.data.attributes.session_notes.data.map((session_notes, index) => {return (
                        <tr key={session_notes.id}>
                            <td>
                                <Link to={`/SessionNotes/${session_notes.id}`}>                        
                                {index + 1}
                                </Link>
                            </td>
                            <td>{session_notes.attributes.SessionTitle}</td>
                            <td>{session_notes.attributes.SessionDate}</td>
                        </tr>
                        )})}
                    </tbody>
                    </Table>
                </Tab>
                <Tab eventKey="#goals" title="Goals">
                    <h2>Tab 2 Content</h2>
                    This is the content for Tab 2.
                </Tab>
                <Tab eventKey="#actions" title="Actions">
                    <h2>Tab 3 Content</h2>
                    This is the content for Tab 3.
                </Tab>
            </Tabs>
        </Row>
        </Container>
    )
}

export default SessionList;
import React from 'react'
import { Link } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'

import UserList from 'pages/UserList'


// const METRICS = gql `
// query GetSessions {
// 	metrics {
// 	  data {
// 		id,
// 		attributes {
// 		  Title,
// 		  Reminder,
// 		  Events,
// 		  token
// 		}
// 	  }
// 	}
//   }
// `

const SESSIONNOTES = gql `
query GetSessions {
	sessionNotesAll {
	  data {
		id,
		attributes {
		  SessionTitle,
		  SessionDate,
		  Notes
		}
	  }
	}
  }
`

export default function Homepage() {

	//const { token } = useToken();
	//const { loading, error, data } = useFetch("http://localhost:1337/api/session-notes-all", token )
	
	//const { loading, error, data } = useQuery(SESSIONNOTES, { useErrorBoundary: true })

	const { loading, error, data } = useQuery(SESSIONNOTES, {
		onError: (error) => {
			console.log("Call back error: " + error.networkError.statusCode)
      		if (error.networkError.statusCode == 401) {
				console.log("Not authenticated.....")
				sessionStorage.removeItem('token')
			}
		}
	})

	if (loading) return <p>Please wait</p>
	
	if (error || data.error) return <p>Error 2 :( ... {error}</p>;
	
	console.log("Homepage.js: " + data)
	
	return (

		<div>
            <UserList />
		</div>
	// 	<div>
	// 	{ data.metrics.data.map((metrics) => {return (
	// 		 <div key={metrics.id} className="review-card">
	// 		 <div className="rating">{metrics.id}</div>
	// 			<h2>{metrics.attributes.Title}</h2>			
	// 			<small>When: {metrics.attributes.Reminder}</small>
	// 			<small>User: {metrics.attributes.token}</small>
	// 			<p><small>Notes: {metrics.attributes.Events}</small></p>
	// 			<p><Link to={`/SessionNotes/${metrics.id}`}>Details.</Link></p>
	// 		 </div>
	// 	)}) }
	// </div>
//     { data.sessionNotesAll.data.map((sessionNotes) => {return (
//         <div key={sessionNotes.id} className="review-card">
//         <div className="rating">{sessionNotes.id}</div>
//            <h2>{sessionNotes.attributes.SessionTitle}</h2>			
//            <small>When: {sessionNotes.attributes.SessionDate}</small>
//            <small>User: Placeholder</small>
//            <p></p>
//            <small>Notes: <ReactMarkdown>{sessionNotes.attributes.Notes}</ReactMarkdown></small>
//            <p><Link to={`/SessionNotes/${sessionNotes.id}`}>Details.</Link></p>
//         </div>
//    )}) }

	)

}
import React from 'react'
import  { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap';
import { useQuery, gql } from '@apollo/client'
import useToken from 'Hooks/useToken'

const USERS = gql `
	query GetUsers {
		usersPermissionsUsers {
			data {
				id
				attributes {
					username
				}
			}
		}
	}
`


/**
 * 
 * query GetUsers {
  usersPermissionsUsers {
    __typename
    data {
      id
      attributes {
        username,
        email,
        Pronouns,
        headshot {
          __typename
          data {
            __typename
            attributes {
              
              size
              height
              width
              name
              caption
            }
          }
        }
      }
    }
  }
}
 */


export default function Header() {

	const { loading, error, data } = useQuery(USERS)

    const { token, setToken } = useToken();

	if (loading) return <p>Please wait</p>
	if (error) return <p>Error 2 :(</p>

    function handleLogout() {
        
        setToken("");
        window.location.reload();
    }

	return (
        <nav className="navbar navbar-dark bg-primary">
		    <div className="site-header">
			<Link to="/"><h1>MSR Portal</h1></Link>
		    </div>
            <div className="ml-auto">
                <Link to={`/AddNewUser`}>
                <Button variant="primary">New User</Button>
                </Link>
                <Button onClick={handleLogout}>Logout</Button>
            </div>
        </nav>
	)        

    //{renderLogout()}
    // <nav className='users'>
	// 	<span>Filter by user:</span>
	// 	{data.usersPermissionsUsers.data.map( user => (
	// 		<Link key={user.id} to={`/User/${user.id}`}>
	// 			{user.attributes.username}
	// 		</Link>
	// 	))}
	// </nav>

}
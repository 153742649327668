import React from 'react'
import { useParams } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'
import { useQuery, gql } from '@apollo/client'
import { Link } from 'react-router-dom'
import { Card, Container, Row, Tab, Tabs, Col, Table, Button } from 'react-bootstrap';
import { EnvelopeAt } from "react-bootstrap-icons";

import SessionList from 'components/SessionList'

import { strapi_config } from 'Constants';

//import useFetch from '../Hooks/useFetch'
//import useToken from '../Hooks/useToken'

const USERDATA = gql `
	query GetUser($id: ID!)  {
		usersPermissionsUser(id: $id) {
			data {
				id
				attributes {
					username,
                    email,
                    headshot {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    session_notes {
                        data {
                            id
                            attributes {
                                SessionTitle
                                SessionDate
                            }
                        }
                    }
				}
			}
		}
	}
`

export default function User() {

	const { id } = useParams()
	//const { token } = useToken()
	
	//const { loading, error, data } = useFetch("http://localhost:1337/api/session-notes-all/" + id, token)
	const { loading, error, data } = useQuery(USERDATA, {
		variables: { id: id }
	})
	
	if (loading) return <p>Please wait</p>
	
	if (error) return <p>Error 2 :(</p>;
	
	console.log(data)
	//console.log(typeof(data))
	//console.log(typeof(error))

    const headshotUrl = strapi_config.url.BASE_STRAPI_URL + data.usersPermissionsUser.data.attributes.headshot.data.attributes.url
    const user = data.usersPermissionsUser.data

	return (
        <Container>
            <Row>
            <Card>
            <Card.Img variant="left" src={headshotUrl} width="100" height="100"/>
                <Card.Body>
                    <Card.Title>{data.usersPermissionsUser.data.attributes.username}</Card.Title>
                    <Card.Text><EnvelopeAt /> {user.attributes.email}</Card.Text>
                    <Link key={user.id} to={`/User/${user.id}`}>
                    </Link>
                </Card.Body>
            </Card>
            </Row>
            <SessionList sessionList={data}/>
        </Container>
	)

}


// <div className="review-card">
// <div className="User ID: ">{data.id}</div>
// <h2>{data.usersPermissionsUser.data.attributes.username}</h2>
// <img src={"http://localhost:1337" + data.usersPermissionsUser.data.attributes.headshot.data.attributes.url} width="100" height="100"></img>
// <p></p>
// <small>Contact: <a href={"mailto:" + data.usersPermissionsUser.data.attributes.email}>{data.usersPermissionsUser.data.attributes.email}</a></small>

// <Link to={`/NewSessionNotes/${id}`}><Button>New Session</Button></Link>

// <table>
//     <thead>
//         <tr>
//             <td><h2>#</h2></td>
//             <td><h2>Session Name</h2></td>
//             <td><h2>Date</h2></td>
//         </tr>
//     </thead>
//     <tbody>
//         {data.usersPermissionsUser.data.attributes.session_notes.data.map((session_notes) => {return (
//             <tr key={session_notes.id} classname = "review-card">
//                 <td>
//                     <Link to={`/SessionNotes/${session_notes.id}`}>                        
//                         {session_notes.id}
//                     </Link>
//                 </td>
//                 <td>{session_notes.attributes.SessionTitle}</td>
//                 <td>{session_notes.attributes.SessionDate}</td>
            
//             </tr>
//             )})}
//     </tbody>
// </table>
// </div>
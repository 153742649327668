import React from 'react'
import { useParams } from 'react-router-dom'
//import useFetch from '../Hooks/useFetch'
import { useQuery, gql } from '@apollo/client'


const METRIC = gql `
	query GetMetric($id: ID!)  {
		metric(id: $id) {
			data {
				id
				attributes {
					Title
					Reminder
					Events
				}
			}
		}
	}
`

export default function Metrics() {

	const { id } = useParams()
	//const { loading, error, respData } = useFetch("http://localhost:1337/api/metrics/" + id)
	const { loading, error, data } = useQuery(METRIC, {
		variables: { id: id }
	})

	if (loading) return <p>Please wait</p>
	
	if (error) return <p>Error 2 :(</p>;
	
	console.log("JediJamesDC28")
	console.log(typeof(data))
	console.log(typeof(error))
	console.log(typeof(loading))
	console.log(data.metric.data.id)
	
	return (
		<div className="review-card">
			<div className="rating">{id}</div>
			<h2>{data.metric.data.attributes.Title}</h2>			
			<small>When: {data.metric.data.attributes.Reminder}</small>
		</div>
	)

}
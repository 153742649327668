import React from 'react';
import { Card, Nav, Tab } from 'react-bootstrap';

const MyCard = () => {
  return (
    <Card>
      <Card.Header>
        <Nav variant="tabs" defaultActiveKey="#tab1">
          <Nav.Item>
            <Nav.Link eventKey="#tab1">Tab 1</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="#tab2">Tab 2</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="#tab3">Tab 3</Nav.Link>
          </Nav.Item>
        </Nav>
      </Card.Header>
      <Tab.Content>
        <Tab.Pane eventKey="#tab1">
          <Card.Body>
            <Card.Title>Tab 1 Content</Card.Title>
            <Card.Text>
              This is the content for Tab 1.
            </Card.Text>
          </Card.Body>
        </Tab.Pane>
        <Tab.Pane eventKey="#tab2">
          <Card.Body>
            <Card.Title>Tab 2 Content</Card.Title>
            <Card.Text>
              This is the content for Tab 2.
            </Card.Text>
          </Card.Body>
        </Tab.Pane>
        <Tab.Pane eventKey="#tab3">
          <Card.Body>
            <Card.Title>Tab 3 Content</Card.Title>
            <Card.Text>
              This is the content for Tab 3.
            </Card.Text>
          </Card.Body>
        </Tab.Pane>
      </Tab.Content>
    </Card>
  );
};

export default MyCard;

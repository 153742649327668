import React, { useState } from 'react';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';

const CaptureAction = ({addNewAction}) => {


  const [text, setText] = useState('');
  const [date, setDate] = useState('');
  const [description, setDescription] = useState('');


  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  return (
    <Container>
      <h1>Add Action:</h1>

      <Form onSubmit={() => addNewAction(text, date, description)}>

        <Row className="align-items-center">
            <Form.Label>Action name</Form.Label>
            <Form.Control
                type="text"
                placeholder="New Action Title"
                value={text}
                onChange={handleTextChange}
            />
            <Form.Label>Target date</Form.Label>
            <Form.Control
                type="date"
                value={date}
                onChange={handleDateChange}
                />
            <Form.Label>Description</Form.Label>
            <Form.Control
                name="actionDescription"
                as="textarea"
                rows={5}
                onChange={handleDescriptionChange}
                />
            
                <Button variant="primary" type="submit">
                Add
                </Button>
            
          </Row>
      </Form>
    </Container>
  );
};

export default CaptureAction;

import React, {useReducer, useState} from "react";
import { Container, Row, Col, Form, Button } from 'react-bootstrap';

import { strapi_config } from 'Constants';

const formReducer = (state, event) => {

    console.log("Loging int formReducer " + event.name + ":" + event.value + ". State:" + state)

    if (event.name == "username") {
        localStorage.setItem('username', event.value);
    }
    if (event.name == "email") {
        localStorage.setItem('email', event.value);
    }
    return {
        ...state,
        [event.name]: event.value
    }
}

const sendMyPost = (url, token, data) => {
    // Simple POST request with a JSON body using fetch

    const myHeaders = new Headers(
        { Authorization: `Bearer ${token}` },
    )

    myHeaders.append('Content-Type', 'application/json')
    const requestOptions = {
        method: 'POST',
        // headers: new Headers(
        //     { Authorization: `Bearer ${token}` },
        //     { 'Content-Type': 'application/json' },
        // ),
        headers: myHeaders,
        body: data
    };
    fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => this.setState({ postId: data.id }));
}


export default function AddNewUser() {

    const [formData, setFormData] = useReducer(formReducer, {});
    const [submitting, setSubmitting] = useState(false)

    const handleChange = event => {
        console.log("Something changed: " + event.target.name + " " + event.target.value)
        setFormData({
            name: event.target.name,
            value: event.target.value,
        });
    }
    const handleSubmit = event => {
        console.log("Add user to CMS...")
        console.log("Trying to JSON it " + JSON.stringify(formData))

        const tokenString = sessionStorage.getItem('token');
	    const token = JSON.parse(tokenString);

        sendMyPost(strapi_config.url.BASE_STRAPI_URL + strapi_config.url.ADD_USER_URI, 
            token, JSON.stringify(formData))


    }

    const handleClear = event => {
        event.preventDefault();
        console.log("Clear Button")
        localStorage.clear()
    }

    return (
        <Container>
        <h1>New User</h1>

        <Row>
            <Col md={8}>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="username">
                    <Form.Label>
                        <p>User Name</p> 
                        <input
                            name="username" 
                            type="text" onChange={handleChange}
                        />
                    </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3" controlId="email">
                    <Form.Label>
                        <p>Email</p> 
                        <input 
                            name="email" 
                            type="email" onChange={handleChange}
                        />
                    </Form.Label>
                </Form.Group>
                <Form.Group className="mb-3" controlId="password">
                    <Form.Label>
                        <p>Password</p> 
                        <input
                            name="password" 
                            type="epassword" onChange={handleChange}
                        />
                    </Form.Label>
                </Form.Group>

                <Row>
                    <Col><Button onClick = {handleSubmit} >Add</Button></Col>
                    <Col><Button onClick = {handleClear} >Clear</Button></Col>
                
                </Row>
            </Form>
            </Col>
            </Row>
        </Container>
    )
}
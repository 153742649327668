import React, { useReducer, useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Form, Button, ListGroup, Col, Row } from 'react-bootstrap';
import { strapi_config } from 'Constants';
import usePost from '../Hooks/usePost'


//import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import TextArea from '@uiw/react-md-editor/lib/components/TextArea';
import TextEditor from 'components/TextEditor';
import CaptureAction from 'components/CaptureAction';
import ActionList from 'components/ActionList';
import { title } from '@uiw/react-md-editor';


const formReducer = (state, event) => {

//    console.log("Loging int formReducer " + event.name + ":" + event.value + ". State:" + state)

    return {
        ...state,
        [event.name]: event.value
    }
}

const sendMyPost = (url, token, data) => {
    // Simple POST request with a JSON body using fetch

    const myHeaders = new Headers(
        { Authorization: `Bearer ${token}` },
    )

    myHeaders.append('Content-Type', 'application/json')
    const requestOptions = {
        method: 'POST',
        // headers: new Headers(
        //     { Authorization: `Bearer ${token}` },
        //     { 'Content-Type': 'application/json' },
        // ),
        headers: myHeaders,
        body: JSON.stringify(data)
    };
    fetch(url, requestOptions)
        .then(response => response.json())
        .then(data => this.setState({ postId: data.id }));
}

export default function NewSessionNotes() {


    const { id } = useParams()
    const [formData, setFormData] = useReducer(formReducer, {});
    const [submitting, setSubmitting] = useState(false)

    const [actions, setActions] = useState([]);

    const addNewAction = (text, date, description) => {
        //e.preventDefault();
        console.log("New action: " + text)
        console.log("total actions: " + actions.length  )
    
        const newAction = {
            text: text,
            date: date,
            description: description,
            complete: false
          };
    
        if (newAction.text.trim() !== '') {
          setActions([...actions, newAction]);
          //setNewItem('');
        }
      };

    const handleSubmit = event => {
        event.preventDefault();

        var sessionNotes = {}
        var postMessage = {}
        var userJSON = {}

        {Object.entries(formData).map(([name, value]) => {
            sessionNotes[name] = value.toString()
        })}

        userJSON["id"] = id.toString()
        const userList = []
        userList[0] = userJSON
        const connect = {}
        connect["connect"] = userList;

        sessionNotes["user"] = connect

        console.log(JSON.stringify(sessionNotes))

        postMessage["data"] = sessionNotes
        console.log("To POST: " + JSON.stringify(postMessage))
        //alert('You have submitted the form.')
        setSubmitting(true);

        const tokenString = sessionStorage.getItem('token');
	    const token = JSON.parse(tokenString);

        sendMyPost(strapi_config.url.BASE_STRAPI_URL + "/api/session-notes-all", token, postMessage)

        
        setTimeout(() => {
            setSubmitting(false);
        }, 3000);
    }

    const handleChange = event => {
       // console.log("Something changed: " + event.target.name + " " + event.target.value)
        setFormData({
          name: event.target.name,
          value: event.target.value,
        });
      }

    return(
        <Container>
            <h1>New Session</h1>
            {submitting &&
                <div>
                    You are submitting the following:
                    <ul>
                        {Object.entries(formData).map(([name, value]) => (
                            <li key={name}><strong>{name}</strong>: {value.toString()}</li>
                        ))}
                    </ul>
                </div>
            }

            <Row>
            <Col md={8}>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="sessionNotesFormTitle">
                    <Form.Control type="text"
                        size='lg' placeholder='Session Title'
                        name="SessionTitle" onChange={handleChange}/>
                </Form.Group>
                <Form.Group as={Row} className="mb-3" controlId="sessionNotesFormDate">
                    <Form.Label column sm="2"><p>Date:</p></Form.Label>
                    <Col sm="10">
                    <Form.Control name="SessionDate" type="date" onChange={handleChange}/>
                    </Col>
                </Form.Group>
                <Form.Group className="mb-3" controlId="sessionNotesFormNotes">
                    <Form.Label><p>Notes:</p></Form.Label>
                    <Form.Control 
                        name="Notes" as="textarea" 
                        rows={8} onChange={handleChange} 
                    />
                </Form.Group>
                <Row>
                    <Col><Button type = "submit" >Save</Button></Col>
                </Row>
            </Form>
            </Col>
            <Col md={4}>
                    <CaptureAction addNewAction={addNewAction}/> 
                    <ActionList data = {actions} />
            </Col>
            </Row>
        </Container>
    )

}